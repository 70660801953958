import { ActionIcon, Anchor, Avatar, Button, Flex, Group, Modal, Text, Tooltip } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconCoin, IconFilter, IconInfoSquare } from "@tabler/icons";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SettlementApi } from "../../apis";
import { AllowSettlementForm } from "../../components/account-balance/allow-settlement-form";
import { PageLayout } from "../../components/layout";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const AccountBalance = () => {
  const breadcrumbs = useBreadcrumb();
  const { currencies } = useSelector((state: any) => state.general);
  const { scfRequestStatus = [], fundingInstructionStatus = [], settlementStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = useState<any[]>([]);
  const tableRef = useRef<ITableRef>(null);
  const [filters, setFilters] = useState<any>({
    month: "",
  });

  const columns = useHeader({
    currencies,
    scfRequestStatus,
    settlementStatus,
    Tag,
    fundingInstructionStatus,
    onClick: (key, record) => {
      setAction([key, record]);
    },
  });

  return (
    <PageLayout
      title="Тооцоо үлдэгдэл"
      subTitle="Санхүүжүүлэгч банкнаас авсан зээлийн эргэн төлөлтийн тооцоо үлдэгдэл"
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={0}>
          <Button disabled>Тооцоо нийлсэн түүх</Button>
          <ActionIcon variant="light" size={36} color="golomt">
            <IconFilter />
          </ActionIcon>
        </Group>,
      ]}>
      <Flex mb={"sm"}>
        <DatePicker
          initialLevel="month"
          inputFormat="YYYY/MM"
          // excludeDate={(date) => {
          //   console.log(date.getDay());
          //   return date.getDate() !== 1;
          // }}
          onChange={(e) => {
            setFilters({
              ...filters,
              month: e ? dayjs(e).format("YYYY-MM") : "",
            });
          }}
          labelFormat="YYYY-MM"
          w={"270px"}
          placeholder="Сар сонгох"
        />
      </Flex>
      <Table ref={tableRef} name="requests.listsss" columns={columns} filters={filters} loadData={(data) => SettlementApi.list(data!)} />

      <Modal
        size="1200px"
        centered
        title={
          <Text size={"xl"} fw={600}>
            <Text color="violet" component="span">
              # {(action[1] && action[1]?.business?.partner?.refCode) || "-"}
            </Text>{" "}
            <Text component="span">,{(action[1] && action[1]?.business?.partner?.businessName) || "-"}</Text>
          </Text>
        }
        opened={action[0] === "details" || action[0] === "respond"}
        onClose={() => setAction([])}>
        <AllowSettlementForm
          reload={async () => {
            await tableRef.current?.reload();
          }}
          settlementDate={filters?.month || new Date()}
          action={action}
          onCloseModal={() => {
            setAction([]);
          }}
        />
      </Modal>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  currencies,
  scfRequestStatus,
  Tag,
  fundingInstructionStatus,
  settlementStatus,
}: {
  onClick: (key: string, record: any) => void;
  currencies: IReference[];
  scfRequestStatus: any;
  Tag: any;
  fundingInstructionStatus: IReference[];
  settlementStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    width: "100px",
    render: (record) => (
      <Group spacing="xs" noWrap>
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <ActionIcon color="" onClick={() => onClick("details", record)}>
            <IconInfoSquare />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Тооцоо зөвшөөрөх" position="bottom">
          <ActionIcon disabled={record?.settlementStatus === "CONFIRMED"} color="" onClick={() => onClick("respond", record)}>
            <IconCoin />
          </ActionIcon>
        </Tooltip>
      </Group>
    ),
  },
  {
    title: "Тооцооны баримт",
    width: "max-content",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {record?.file ? (
          <Anchor href={record?.file} target="_blank">
            файл үзэх
          </Anchor>
        ) : (
          "-"
        )}
      </Text>
    ),
  },
  {
    title: "Тооцооны статус",
    width: "max-content",
    render: (record) =>
      record.settlementStatus
        ? Tag(
            settlementStatus.find((item: IReference) => item.code === record.settlementStatus)?.color || "green",
            settlementStatus.find((item: IReference) => item.code === record.settlementStatus)?.name || "dark",
          )
        : "-",
  },
  {
    title: "Илгээсэн огноо",
    width: "max-content",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {record?.sentDate ? dateTimeFormat(record.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Тооцоо нийлсэн",
    width: "max-content",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {record?.confirmedDate ? dateTimeFormat(record.confirmedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Санхүү ажилтан",
    width: "max-content",
    render: (record) =>
      record?.financeStaff ? (
        <Flex gap={8} align="center">
          <Avatar src={record?.financeStaff?.avatar} />
          <Text color="gray.7" fw={500} w={"max-content"}>
            {record?.financeStaff?.lastName ? record?.financeStaff?.lastName[0] + ". " : ""}
            {record?.financeStaff?.firstName || "-"}
          </Text>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Банкны нэр",
    width: "max-content",
    render: (record) => (
      <Text color="gray.7" fw={500} w={"max-content"}>
        {record?.bankName || "-"}
      </Text>
    ),
  },
  {
    title: "Партнерийн нэр",
    width: "max-content",
    render: (record) => {
      if (record?.business)
        return (
          <Flex direction="column" w={"max-content"}>
            <Anchor>{record?.business?.partner?.refCode || "-"} #</Anchor>
            <Text color="gray.7" fw={500} w={"max-content"}>
              {record?.business?.partner?.businessName || "-"}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Бизнесийн нэр",
    width: "max-content",
    render: (record) => {
      if (record.business)
        return (
          <Flex direction="column">
            <Anchor>{record?.business?.refCode || "-"} #</Anchor>
            <Text color="gray.7" fw={500} w={"max-content"}>
              {record?.business?.profileName || "-"}
            </Text>
          </Flex>
        );
      else return "-";
    },
  },
  {
    title: "Эхний үлдэгдэл",
    width: "max-content",
    render: (record) => (
      <Group position="right">
        <Flex direction="column" align="end">
          <Anchor fw={500}>{currencyFormat(record.firstAmount)}</Anchor>
          <Text color="dimmed" fw={500}>
            {record?.firstInvCount || "0"} нэхэмжлэх
          </Text>
        </Flex>
      </Group>
    ),
  },
  {
    title: "Тухайн сард нэмэгдсэн",
    width: "max-content",
    render: (record) => (
      <Group position="right">
        <Flex direction="column" align="end">
          <Anchor fw={500}>{currencyFormat(record.confirmedAmount)}</Anchor>
          <Text color="dimmed" fw={500}>
            {record.confirmedInvCount} нэхэмжлэх
          </Text>
        </Flex>
      </Group>
    ),
  },
  {
    title: "Тухайн сард төлсөн",
    width: "max-content",
    render: (record) => (
      <Group position="right">
        <Flex direction="column" align="end">
          <Anchor fw={500}>{currencyFormat(record?.paidAmount)}</Anchor>
          <Text color="dimmed" fw={500}>
            {record?.paidInvCount} нэхэмжлэх
          </Text>
        </Flex>
      </Group>
    ),
  },
  {
    title: "Эцсийн үлдэгдэл",
    width: "max-content",
    render: (record) => (
      <Group position="right">
        <Flex direction="column" align="end">
          <Anchor fw={500}>{currencyFormat(record?.lastAmount || 0)}</Anchor>
          <Text color="dimmed" fw={500}>
            {record?.lastInvCount || 0} нэхэмжлэх
          </Text>
        </Flex>
      </Group>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/",
    label: "Санхүүжилт",
  },
  {
    label: "Тооцоо үлдэгдэл",
  },
];
