import { ActionIcon, Anchor, Badge, Box, Flex, Group, Text, Tooltip } from "@mantine/core";
import { IconCreditCard, IconFileInvoice } from "@tabler/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ScfRequestApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

export const ReceivedLendList = () => {
  const { programStatus, currencies, loanStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();

  const columns = useHeader({
    programStatus,
    currencies,
    loanStatus,
    onClick: (key, item) => {
      switch (key) {
        case "pay":
          navigate(`/received/${item?.lbfRequest?.id}`, { state: { ...item, payment: true } });
          break;
        case "details":
          navigate(`/received/${item?.lbfRequest?.id}`, { state: { ...item } });
          break;

        default:
          break;
      }
    },
  });

  return <Table name="received.collateral.list" columns={columns} filters={{ financeType: "LBF" }} loadData={(data) => ScfRequestApi.completedList(data!)} />;
};

const useHeader = ({
  onClick,
  programStatus,
  currencies,
  loanStatus,
}: {
  onClick: (e1: string, e2: any) => void;
  programStatus: IReference[];
  currencies: IReference[];
  loanStatus: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Group w={"max-content"}>
          {record.loanStatus === "OPEN" && (
            <Tooltip label="Төлөх" position="bottom">
              <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("pay", record)}>
                <IconCreditCard />
              </ActionIcon>
            </Tooltip>
          )}
          <Tooltip label="Зээлийн данс" position="bottom">
            <ActionIcon variant="light" color={"golomt"} onClick={() => onClick("details", record)}>
              <IconFileInvoice />
            </ActionIcon>
          </Tooltip>
        </Group>
      );
    },
  },
  {
    title: "Дансны төлөв",
    render: (record) => {
      return (
        <Box w="max-content">
          {record?.loanStatus === "OPEN" && (
            <Badge variant="dot" color="green">
              Нээлттэй
            </Badge>
          )}
          {record?.loanStatus === "CLOSED" && (
            <Badge variant="dot" color="gray">
              Хаалттай
            </Badge>
          )}
        </Box>
      );
    },
  },
  {
    title: "Үүссэн огноо",
    render: (record) => {
      return (
        <Text w="max-content">
          <Anchor># {record.resolutionRef}</Anchor>
          <Text fz={"xs"}>{dateTimeFormat(record.createdAt)} </Text>
        </Text>
      );
    },
  },
  {
    title: "ТТД",
    render: (record) => {
      return <Text>{record.buyerRegNumber}</Text>;
    },
  },
  {
    title: "Нийлүүлэгч",
    render: (record) => (
      <Text w="max-content">
        <Text>{record.supplier?.partner.businessName}</Text>
        <Anchor># {record.supplier?.partner.refCode}</Anchor>
      </Text>
    ),
  },

  {
    title: "Зээлийн дансны дугаар",
    render: (record) => {
      return (
        <Text w="max-content">
          <Anchor>{record.number}</Anchor>
        </Text>
      );
    },
  },
  {
    title: "Валют",
    render: (record) => <Text w="max-content">{currencies?.find((f: any) => f?.code === record?.currency)?.name || "Төгрөг"}</Text>,
  },

  {
    title: "Зээл авсан дүн",
    render: (record) => (
      <Group position="right">
        <Text w="max-content"> {currencyFormat(record.amount)} </Text>
      </Group>
    ),
  },
  {
    title: "Эргэн төлөх огноо",
    render: (record) => (
      <Group position="right">
        <Text w="max-content"> {record.repaymentDate ? dateTimeFormat(record.repaymentDate) : "-"} </Text>
      </Group>
    ),
  },
  {
    title: "Олголт хүлээн авсан данс",
    render: (record) => (
      <Text w="max-content">
        <Anchor>{record.receiverAccName}</Anchor>
        <Text>{record.receiverAccNum}</Text>
      </Text>
    ),
  },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) => (
      <div>
        <Text w="max-content">
          <Text>{record.supplier?.profileName}</Text>
          <Anchor># {record.supplier.refCode}</Anchor>
        </Text>
      </div>
    ),
  },
  {
    title: "Хөтөлбөр нэр",
    render: (record) => (
      <Text w="max-content">
        <Text>{record.lbfProgram?.name}</Text>
        <Anchor># {record.lbfProgram.refCode}</Anchor>
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэхийн төлбөрийн төлөв",
    render: (record) => (
      <Flex justify="center">
        <Badge color={record?.invoicePaymentStatus?.color}>{record?.invoicePaymentStatus?.name}</Badge>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэхийн хугацаа хэтрэлт",
    render: (record) => (
      <Flex justify="center">
        <Badge color={record?.overdueStatus?.color}>{record?.overdueStatus?.name}</Badge>
      </Flex>
    ),
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => (
      <Flex w={"max-content"}>
        <Anchor>#{record.invoiceRef}</Anchor>
      </Flex>
    ),
  },
  {
    title: "Хүсэлт №",
    render: (record) => (
      <Flex w={"max-content"}>
        <Anchor>#{record.lbfRequest.refCode || "-"} </Anchor>
      </Flex>
    ),
  },
];
