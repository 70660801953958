/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, createStyles, Drawer, Flex, Space, Text, UnstyledButton } from "@mantine/core";
import { IconCalendar } from "@tabler/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FirebaseFcmApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";

type NotificationDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Notification = {
  id: string;
  body: string;
  createdAt: string;
  notifyStatus: "SUCCESS" | "FAILED";
  objectId: string;
  objectType: string;
  seen: boolean;
  title: string;
};

const Notifications = ({ isOpen, onClose }: NotificationDrawerProps) => {
  const [seenType, setSeenType] = useState<"ALL" | "SEEN" | "UNSEEN">("ALL");
  const [loading, setLoading] = useState(false);
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const { classes } = styles();

  const fetchNotifications = useCallback(
    async (reset = false) => {
      if (loading || (reset === false && !hasMore)) return;
      setLoading(true);

      if (reset) {
        setPage(1);
      }

      try {
        const res = await FirebaseFcmApi.list({
          offset: {
            page: reset ? 1 : page,
            limit: 10,
          },
        });

        if (res.rows.length === 0) {
          setHasMore(false);
        } else {
          setAllNotifications((prev) => (reset ? res.rows : [...prev, ...res.rows]));
          setPage(reset ? 2 : page + 1);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }

      setLoading(false);
    },
    [page, loading, hasMore],
  );

  useEffect(() => {
    if (isOpen) {
      setHasMore(true);
      fetchNotifications(true);
    }
  }, [isOpen]);

  useEffect(() => {
    let filteredNotifications = allNotifications;
    if (seenType === "SEEN") {
      filteredNotifications = allNotifications.filter((n) => n.seen);
    } else if (seenType === "UNSEEN") {
      filteredNotifications = allNotifications.filter((n) => !n.seen);
    }
    setNotifications(filteredNotifications);
  }, [seenType, allNotifications]);

  // Infinite scroll observer
  useEffect(() => {
    if (!observerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchNotifications();
        }
      },
      { root: scrollRef.current, threshold: 1.0 },
    );

    const currentObserverRef = observerRef.current;

    observer.observe(currentObserverRef);

    return () => {
      if (currentObserverRef) observer.unobserve(currentObserverRef);
    };
  }, [loading, hasMore]);

  const handleNotification = async (notification: Notification) => {
    if (!notification.seen) {
      try {
        await FirebaseFcmApi.seen(notification.id);

        setAllNotifications((prev) => prev.map((n) => (n.id === notification.id ? { ...n, seen: true } : n)));

        fetchNotifications(true);
      } catch (error) {
        console.error("Failed to mark notification as seen", error);
      }
    }
  };

  return (
    <Drawer
      size={620}
      padding="md"
      position="right"
      opened={isOpen}
      onClose={onClose}
      title={
        <Text color="gray.7" fz={"lg"} fw={700}>
          Мэдэгдэл
        </Text>
      }
      styles={{
        drawer: { display: "flex", flexDirection: "column" },
        body: { display: "flex", flexDirection: "column", flex: 1, height: `calc(100vh - 76px)` },
      }}>
      {/* Tab Buttons */}
      <Flex align="center" gap="sm">
        {["ALL", "SEEN", "UNSEEN"].map((type) => (
          <UnstyledButton key={type} onClick={() => setSeenType(type as any)}>
            <Badge color={seenType === type ? "violet" : "gray"} size="lg">
              {type === "ALL" ? "Бүгд" : type === "SEEN" ? "Уншсан" : "Уншаагүй"}
            </Badge>
          </UnstyledButton>
        ))}
      </Flex>

      <Space h={"lg"} />

      {/* Notifications List */}
      <div ref={scrollRef} style={{ overflowY: "auto", flex: 1, height: "100%" }}>
        {notifications.map((notification) => (
          <Flex
            key={notification.id}
            align="center"
            justify="space-between"
            style={{
              padding: "1rem",
              borderBottom: "1px solid #efefef",
              backgroundColor: notification.seen ? "" : "#f3eaf7",
              cursor: "pointer",
              transition: "background 150ms ease-in-out",
            }}
            className={classes.notification}>
            <Flex direction="column" style={{ flex: 1, paddingRight: 10 }} onClick={() => handleNotification(notification)}>
              <Box>
                <Text size="sm" fw={500}>
                  {notification.title}
                </Text>
                <Text size="sm">{notification.body}</Text>
              </Box>
              <Flex justify="flex-end" align="center" gap="sm">
                <IconCalendar size={18} color="#8931b2" />
                <Text size="sm" color="gray">
                  {dateTimeFormat(notification.createdAt)}
                </Text>
              </Flex>
            </Flex>

            {!notification.seen && <Box style={{ width: 10, height: 10, borderRadius: 100, background: "#8931b2" }} />}
          </Flex>
        ))}

        {/* Loader and Infinite Scroll Trigger */}
        <div ref={observerRef} style={{ height: "1px" }}></div>
        {loading && <Text align="center">Ачаалж байна...</Text>}
      </div>
    </Drawer>
  );
};

export default Notifications;

const styles = createStyles((theme) => ({
  notification: {
    padding: "1rem",
    borderRadius: theme.radius.md,
    cursor: "pointer",
    borderBottom: "1px solid #efefef",
    transition: "background 150ms ease-in-out",
    [`&:last-child`]: {
      borderBottom: 0,
    },
    [`&:hover`]: {
      backgroundColor: "#fbfbfb !important",
    },
  },
}));
