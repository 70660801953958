import { Anchor, Badge, Divider, Flex, Grid, Group, Paper, Skeleton, Space, Text, createStyles } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconArrowDown, IconArrowUp, IconCalendar, IconCash, IconFileDollar, IconReceipt, IconReceipt2 } from "@tabler/icons";
import dayjs from "dayjs";
import { useState } from "react";
import useSwr from "swr";
import { dashboardApi } from "../../apis";
import { FundedInvoiceList } from "../../components/dashboard/funded-invoice-list";
import { FundingInformationChart } from "../../components/dashboard/funding-information-chart";
import { ProgramLimitChart } from "../../components/dashboard/program-limit-chart";
import { useScreenDetector } from "../../components/screen-detector";
import { currencyFormat } from "../../utils";

const now = new Date();

export function Dashboard() {
  const { classes, theme } = useStyles();
  const { isMobile } = useScreenDetector();
  const [filter, setFilter] = useState<any>({
    startDate: dayjs(now).subtract(14, "day").format(),
    date: dayjs(now).format(),
    endDate: dayjs(now).format(),
    structureMonth: dayjs(now).format(),
  });

  const { data = {} } = useSwr<any>(
    `/api/dashboard/main?${JSON.stringify({ ...filter })}`,
    async () => await dashboardApi.main({ ...filter, startDate: dayjs(filter.startDate).format(), endDate: dayjs(filter.endDate).format() }),
    {
      fallback: {},
    },
  );

  return (
    <div className={classes.root}>
      <Group position="apart" spacing={"xs"} mb={"xs"}>
        <Text size={"xl"} fw={600}>
          Тоон тойм
        </Text>
        <DatePicker
          excludeDate={(date) => {
            return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
          }}
          value={new Date(filter.date)}
          onChange={(e) => {
            if (e)
              setFilter({
                ...filter,
                date: e,
              });
            else {
              setFilter({
                ...filter,
                date: dayjs(new Date()).format(),
              });
            }
          }}
          size="xs"
          style={{ borderRadius: "5px" }}
          variant="unstyled"
          bg={theme.colors.golomt[0]}
          miw={isMobile ? "100%" : "240px"}
          placeholder="Огноо хайх"
          icon={<IconCalendar size={"1.2rem"} />}
          inputFormat="YYYY-MM-DD"
          mr={isMobile ? 0 : "sm"}
        />
      </Group>
      <Grid>
        {data?.numberSurvey ? (
          data?.numberSurvey?.map((stat: any, index: number) => {
            const DiffIcon = stat.count > 0 ? IconArrowUp : IconArrowDown;

            return (
              <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
                <Paper withBorder key={stat.key} radius={"md"}>
                  <Group spacing={8} p={"sm"} noWrap pb={"xs"}>
                    {index === 0 && <IconReceipt size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 1 && <IconReceipt2 size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 2 && <IconFileDollar size={"1.2rem"} color={theme.colors.gray[6]} />}
                    {index === 3 && <IconCash size={"1.2rem"} color={theme.colors.gray[6]} />}
                    <Text fz={"sm"} fw={600} color={theme.colors.gray[6]}>
                      {stat.name}
                    </Text>
                  </Group>
                  <Group>
                    <div className={classes.grdnt}></div>
                    <Space />
                    <Text fz={"xl"} fw={700}>
                      {currencyFormat(stat.amount || 0)}
                    </Text>
                  </Group>
                  <Group position="right" p={"sm"}>
                    <Text size={"xs"} color={theme.colors.gray[6]}>
                      Өмнөх өдрөөс
                    </Text>
                    <Badge color={stat.changedAmount > 0 ? "green" : "red"} className={stat.changedAmount > 0 ? classes.green : classes.red}>
                      <Flex align={"center"} gap={8}>
                        <DiffIcon size={"0.8rem"} stroke={3} />
                        <Text>{Math.abs(stat?.changedAmount || 0)} %</Text>
                      </Flex>
                    </Badge>
                  </Group>
                </Paper>
              </Grid.Col>
            );
          })
        ) : (
          <>
            {[1, 2, 3, 4].map((index: number) => {
              return (
                <Grid.Col sm={12} md={6} lg={4} xl={3} key={index}>
                  <Skeleton h={"121px"} w={"100%"} />
                </Grid.Col>
              );
            })}
          </>
        )}

        <Grid.Col>
          <FundingInformationChart data={data} filter={filter} setFilter={setFilter} />
        </Grid.Col>
        <Grid.Col md={12} lg={7} xl={8}>
          <ProgramLimitChart data={data} />
          <Space h={"sm"} />
          <FundedInvoiceList data={data} />
        </Grid.Col>
        <Grid.Col md={12} lg={5} xl={4}>
          <Paper p={"sm"} withBorder>
            <Text fz={"lg"} fw={600}>
              Сүүлд авсан санхүүжилт
            </Text>

            <Space h={"sm"} />
            <Flex direction={"column"} gap={"sm"}>
              {data?.recentlyFinanced?.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <Divider h={"sm"} />
                    <Flex w={"100%"} gap={"sm"}>
                      <Flex align={"center"} direction={"column"} w={"100%"}>
                        <Group w={"100%"} position="apart">
                          <Text fw={600}>{item.businessName}</Text>
                          <Text fz={"sm"} color="gray" align="end">
                            {dayjs(new Date())?.diff(dayjs(item?.createdAt), "hour")} цаг
                          </Text>
                        </Group>

                        <Group position="apart" w={"100%"} mt={"xs"}>
                          <Text fz={"sm"} color="golomt">
                            # {item.businessRef}
                          </Text>
                          <Text align="end">{currencyFormat(item.amount)}</Text>
                        </Group>
                      </Flex>
                    </Flex>
                  </div>
                );
              })}
              <Group position="right">
                <Anchor size={"xs"}>Бүгдийг харах</Anchor>
              </Group>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.fn.linearGradient(45, theme.colors.golomt[3], theme.colors.golomt[6]),
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
