import { HttpRequest } from "../utils";

type Iscr = {
  invoiceId: string;
  requestedAmount: number;
  contractfile: string;
};

type ISupplierLedCreate = {
  programId: string;
  invoices: {
    id: string;
    requestedAmount: number;
  }[];
  contractFiles: string[];
};

type IBuyerLedCreate = {
  programId: string;
  invId: string;
  requestedAmount: number;
  contractFiles: [string];
};

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  const res = await httpRequest.get("/api/request", data);
  return res;
};

export const get = async (id: string, type: "scf" | "lbf") => {
  const res = await httpRequest.get(`/api/request/${type}/${id}`);
  return res;
};

export const create = async (data: Iscr) => {
  const res = await httpRequest.post(`/api/request`, data);
  return res;
};

export const send = async (id: string) => {
  const res = await httpRequest.put(`/api/request/${id}/send`);
  return res;
};

export const completedList = async (data: any) => {
  const res = await httpRequest.get("/api/request/completed", data);
  return res;
};

export const supplierLedCreate = async (data: ISupplierLedCreate) => {
  const res = await httpRequest.post(`/api/request/supplier_led`, data);
  return res;
};

export const buyerLedCreate = async (data: IBuyerLedCreate) => {
  const res = await httpRequest.post(`/api/request/buyer_led`, data);
  return res;
};

export const createLbf = async (data: { lbfProgramId: string; invId: string; requestedAmount: number }) => await httpRequest.post(`/api/request/lbf`, data);

export const closeAccount = async (id: string, type: "scf" | "lbf") => {
  const res = await httpRequest.get(`/api/request/${type}/close/${id}`);
  return res;
};
