import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  const res = await httpRequest.get("/api/repayment", data);
  return res;
};

export const get = async (id: string) => {
  const res = await httpRequest.get(`/api/repayment/${id}`);
  return res;
};

export const pay = async (id: string, data: IForm | any) => {
  const res = await httpRequest.put(`/api/repayment/${id}/pay`, data);
  return res;
};

export const reminder_history = async (data: any) => {
  const res = await httpRequest.get("/api/repayment/reminder_history", data);
  return res;
};

export const history = async (data: any) => {
  //Эргэн төлөлтийн гүйлгээний түүх
  const res = await httpRequest.get("/api/repayment/history", data);
  return res;
};

type IForm = {
  amount: number;
  method: string;
  creditAccountId: string;
  addInfo: string;
  files: string[];
};
