import { Badge, Flex, Grid, Input, Paper, Space, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";
import { NumberCurrencyField } from "../form/number-currency-field";

export const LendDetailsRequestBuyer = ({ action, values }: { action: any[]; values: { requestedAmount: number } }) => {
  const data: null | any = action[1] && action[1];
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const booleanIcon = (min: number, mid: number, max: number) => {
    switch (true) {
      case min > mid || mid > max: {
        return <IconX size={20} color="red" />;
      }
      case min <= mid && mid <= max: {
        return <IconCheck size={20} color="green" />;
      }

      default: {
        return "-";
      }
    }
  };

  return (
    <Paper py={"lg"}>
      <Text color="gray.8" fw={600}>
        Хүсэлтийн ерөнхий мэдээлэл
      </Text>
      <Space h={"lg"} />
      <Grid>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Код, огноо цаг">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                {dateTimeFormat(data?.createdAt)}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт гаргасан ажилтан">
            <Text color="golomt" fz={"sm"} tt={"capitalize"}>
              {data?.receiverFinUser?.lastName ? data?.receiverFinUser?.lastName[0] + ". " : ""}
              {data?.receiverFinUser?.firstName || "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлтийн төлөв">
            <div>
              <Badge color="gray" variant="outline">
                Түр төлөв
              </Badge>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн код">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {user?.clientCode || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Харилцагчийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt" tt={"capitalize"}>
                # {data?.receiverBusiness?.partner?.refCode || "-"}
              </Text>
              <Text fz={"sm"} component="span">
                , {data?.receiverBusiness?.partner?.businessName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Татвар төлөгчийн дугаар">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.receiverBusiness?.regNumber || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хүсэлт хамаарах бизнес">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.receiverBusiness?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.receiverBusiness?.profileName || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Хөтөлбөрийн нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.lbfProgram?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.lbfProgram?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Бүтээгдэхүүний нэр">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                # {data?.lbfProgram?.lbfProduct?.refCode || "-"}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.lbfProgram?.lbfProduct?.name || "-"}
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col>
          <Text color="gray.8" fw={600}>
            Нэхэмжлэхийн шалгуур хангах байдал
          </Text>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилт хүсэх дүн">
            <div>
              <NumberCurrencyField name="requestedAmount" placeholder="Finance_Amount" disabled={action[0] === "details"} />
            </div>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {data?.paymentDate ? dateTimeFormat(data?.paymentDate) : "-"}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Зээл төлөх огноо">
            <Text fz={"sm"} color="golomt">
              {dayjs(data?.paymentDate).add(data?.lbfProgram?.lbfProduct?.buyerTermDays, "days").format("YYYY-MM-DD hh:mm")}
            </Text>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Санхүүжилтийн хэмжээ шалгуур">
            <Flex align="center" gap={"xs"}>
              {booleanIcon(
                (data?.amountToPay * data?.lbfProgram?.lbfProduct?.minOfInv) / 100,
                values.requestedAmount,
                (data?.amountToPay * data?.lbfProgram?.lbfProduct?.maxOfInv) / 100,
              )}
              <Text component="span" fz={"sm"}>
                {currencyFormat((data?.amountToPay * data?.lbfProgram?.lbfProduct?.minOfInv) / 100)} -{" "}
                {currencyFormat((data?.amountToPay * data?.lbfProgram?.lbfProduct?.maxOfInv) / 100)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл">
            <Flex align="center" gap={"xs"}>
              <IconCheck size={"1.2rem"} color="green" />
              <Text component="span" fz={"sm"}>
                {currencyFormat(data?.amountToPay)}
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Нэхэмжлэх хугацаа">
            <Flex align="center" gap={"xs"}>
              {dayjs(new Date()).diff(data?.confirmedDate, "days") > 0 ? <IconCheck size={"1.2rem"} color="green" /> : <IconX size={"1.2rem"} color="red" />}
              <Text component="span" fz={"sm"}>
                {data?.confirmedDate ? dayjs(new Date()).diff(data?.confirmedDate, "days") : "-"} хоног
              </Text>
            </Flex>
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col sm={12} md={6} lg={4}>
          <Input.Wrapper label="Олголт хүлээн авах данс">
            <div>
              <Text component="span" fz={"sm"} color="golomt">
                {data?.receiverAcc?.number}
              </Text>
              <Text component="span" fz={"sm"}>
                , {data?.receiverAcc?.name}, {data?.receiverAcc?.bankName},{" "}
                <Text component="span" color="gray" fz={"xs"}>
                  ({data?.receiverAcc?.shortName})
                </Text>
              </Text>
            </div>
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
